import React, { Component } from 'react';

class ForgotPasswordPage extends Component {
  render() {
    return (
      <div>
        <p className="body-content">Forgotten Password Form</p>
      </div>
    );
  }
}

export default ForgotPasswordPage;
